export const CAMPUS_URL = 'https://lms.aiffel.io';
export const KDC_URL = 'https://kdc.aiffel.io';
export const ONLINE_URL = 'https://online.aiffel.io';

export const MAPPING_INFO = {
  1: {
    id: '1',
    name: 'Gangnam',
    url: CAMPUS_URL,
  },
  2: {
    id: '2',
    name: 'Main',
    url: CAMPUS_URL,
  },
  4: {
    id: '4',
    name: 'Daejeon',
    url: CAMPUS_URL,
  },
  8: {
    id: '8',
    name: 'Dev_Test',
    url: CAMPUS_URL,
  },
  11: {
    id: '11',
    name: 'Yangjae',
    url: CAMPUS_URL,
  },
  12: {
    id: '12',
    name: 'K_Digital_Credit',
    url: KDC_URL,
  },
  18: {
    id: '18',
    name: 'Busan',
    url: CAMPUS_URL,
  },
  20: {
    id: '20',
    name: 'Ulsan',
    url: CAMPUS_URL,
  },
  21: {
    id: '21',
    name: 'Daegu',
    url: CAMPUS_URL,
  },
  22: {
    id: '22',
    name: '(new) Gangnam',
    url: CAMPUS_URL,
  },
  23: {
    id: '23',
    name: 'SOCAR',
    url: CAMPUS_URL,
  },
  24: {
    id: '24',
    name: 'Biz',
    url: ONLINE_URL,
  },
  27: {
    id: '27',
    name: 'Hanhwa',
    url: ONLINE_URL,
  },
  29: {
    id: '29',
    name: 'boaz',
    url: ONLINE_URL,
  },
  30: {
    id: '30',
    name: 'University of Seoul',
    url: ONLINE_URL,
  },
  32: {
    id: '32',
    name: 'CORE',
    url: CAMPUS_URL,
  },
  33: {
    id: '33',
    name: 'RESEARCH',
    url: CAMPUS_URL,
  },
  34: {
    id: '34',
    name: 'AI_CAMP',
    url: ONLINE_URL,
  },
  35: {
    id: '35',
    name: 'LG전자',
    url: ONLINE_URL,
  },
  131: {
    id: '131',
    name: 'SW campus',
    url: CAMPUS_URL,
  },
  196: {
    id: '196',
    name: 'Data Science',
    url: CAMPUS_URL,
  },
} as const;

export type LocalSchoolIdType = keyof typeof MAPPING_INFO;

// find allow campus only ids using MAPPING_INFO keys
export const ALLOW_CAMPUS_IDS = Object.entries(MAPPING_INFO).filter(
  ([key, value]) => {
    return value.url === CAMPUS_URL;
  },
);
export const ALLOW_KDC_IDS = Object.entries(MAPPING_INFO).filter(
  ([key, value]) => {
    return value.url === KDC_URL;
  },
);

export const ALLOW_ONLINE_IDS = Object.entries(MAPPING_INFO).filter(
  ([key, value]) => {
    return value.url === ONLINE_URL;
  },
);
