import { BUILD, BUILD_USAGE } from '@constants';
import { isMobileState } from '../../mobile-detector';
import { CaretDown, CaretUp } from 'phosphor-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import './Footer.scss';
import classNames from 'classnames';

const serviceNavLink = [
  { name: '아이펠 캠퍼스', link: 'https://aiffel.io' },
  { name: '아이펠 내일배움클래스', link: 'https://kdc.aiffel.io' },
];

const aiffelLogoImgObj = {
  src: '/images/footer/aiffel_logo-w.png',
  alt: '아이펠 로고, 화이트',
};
const externalServiceLink = [
  {
    image: '/images/footer/youtube_logo.png',
    link: 'https://www.youtube.com/c/%EB%AA%A8%EB%91%90%EC%9D%98%EC%97%B0%EA%B5%AC%EC%86%8C',
    alt: '모두연 유투브',
  },
  {
    image: '/images/footer/facebook_logo.png',
    link: 'https://www.facebook.com/lab4all',
    alt: '모두연 페이스북',
  },
  {
    image: '/images/footer/brunch_logo.png',
    link: 'https://brunch.co.kr/@modulabs',
    alt: '모두연 브런치',
  },
];

const CompanyInfoView = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <div className={classNames('footer__company-info', isMobile && '--mobile')}>
      대표 김승일 | 개인정보보호책임자 안우진 | 서울특별시 강남구 강남대로 324
      역삼디오슈페리움 2층 | cs@aiffel.io | 사업자등록번호: 517-88-00184 |
      통신판매업신고: 2017-서울강남-04920 | 고객센터: 070 - 7743 - 5882 |
      주식회사 모두의연구소는 전자상거래 등에서의 소비자보호에 관한 법률에 따른
      통신판매업을 영위하고 있습니다.
    </div>
  );
};

const Footer: React.FC = () => {
  const isMobile = useRecoilValue(isMobileState);
  const [isMobileInfoOpend, setIsMobileInfoOpend] = useState(false);

  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className={'footer__inner__top'}>
          <div className="footer__inner__top-left">
            <div className="footer-logo">
              <img
                src={aiffelLogoImgObj.src}
                alt={aiffelLogoImgObj.alt}
                width={70}
              />
            </div>
            {/* <p className="footer-logo-subtitle">모두를 위한 AI 클래스</p> */}
            <div className="footer-service-link">
              <ul>
                {serviceNavLink.map((service, idx) => {
                  return (
                    <li key={idx}>
                      <a href={service.link}>{service.name}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="footer__inner__top-right">
            <div className="footer__external-links">
              {externalServiceLink.map((service, idx) => {
                return (
                  <a
                    key={idx}
                    href={service.link}
                    target={'_blank'}
                    rel="noreferrer noopener"
                  >
                    <img src={service.image} alt={service.alt} />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        <hr />
        <div className={'footer__inner__bottom'}>
          <div className="footer__inner__bottom-left">
            <div className={'footer__terms-policy'}>
              {BUILD_USAGE !== BUILD.USAGE.LEGACY && (
                <>
                  <Link to="/private-policy">개인정보처리방침</Link>
                  <Link to="/terms-of-use">이용약관</Link>
                  {/* {BUILD_USAGE === BUILD.USAGE.K_DIGITAL && (
                    <Link to="/refund-policy">환불정책</Link>
                  )} */}
                </>
              )}
            </div>
            <div className={'footer__company'}>
              <div className={'footer__company-title'}>
                (주)모두의연구소 사업자 정보
                {isMobile && (
                  <span
                    onClick={() => setIsMobileInfoOpend(!isMobileInfoOpend)}
                  >
                    {isMobileInfoOpend ? (
                      <CaretUp size={16} />
                    ) : (
                      <CaretDown size={16} />
                    )}
                  </span>
                )}
              </div>
              {isMobile && isMobileInfoOpend && <CompanyInfoView />}
              {!isMobile && <CompanyInfoView />}
            </div>
          </div>
          <div className="footer__inner__bottom-right">
            <span>
              Copyright &copy;{currentYear} AIFFEL. All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
