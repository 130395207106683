import { Route, Routes } from 'react-router-dom';
import routes from '../../routes';
import PublicOnly from './PublicOnly';
import RequiredAuth from './RequireAuth';

const renderingRoutes = [
  routes.kdc.PurchasePageRoute,
  routes.kdc.PurchaseConfirmPageRoute,

  routes.common.FindPasswordPageRoute,
  routes.common.ResetPasswordPageRoute,
  routes.common.AttendancePageRoute,
  routes.common.NodesPageRoute,
  routes.common.StepsPageRoute,
  routes.common.ReadonlyStepsPageRoute,
  routes.common.ReleaseNotePageRoute,
  routes.common.PrintPageRoute,
  routes.common.ClassroomPageRoute,
  routes.common.ClassroomDetailPageRoute,
  routes.common.AccountPageRoute,
  routes.common.RedirectPageRoute,

  routes.online.InvitationRoute,
  routes.online.ExpiredInvitationPageRoute,
  routes.online.RegisterConfirmationRoute,

  /** 통합 로그인 페이지 */
  routes.common.IntegratedSignInPageRoute, // stand-alone route
  routes.common.IntegratedSignUpPageRoute, // stand-alone route
  routes.common.SelectCampusPageRoute, // stand-alone route,
  routes.common.RegisterInvitedUserPageRoute, // stand-alone route

  routes.common.IntergratedTermsPageRoute, // stand-alone route
  routes.common.IntergratedPrivacyPolicyPageRoute, // stand-alone route
  routes.common.IntergratedMarketingPageRoute,

  routes.online.TermsAndExtraInfoPageRoute, // 초대 유저 추가 정보 등록 - stand-alone
  /** 공지사항 */
  routes.common.NoticeBoardPageRoute,
  routes.common.NoticePostPageRoute,

  routes.kdc.NotFoundPageRoute,
];

export default function OnlineRoutes() {
  return (
    <>
      <Routes>
        {renderingRoutes.map((route, key) => (
          <Route
            key={key}
            path={route.path}
            element={
              route.private || route.allowedPermissions ? (
                <RequiredAuth allowedRoles={route.allowedPermissions}>
                  <route.component />
                </RequiredAuth>
              ) : route.publicOnly ? (
                <PublicOnly>
                  <route.component />
                </PublicOnly>
              ) : (
                <route.component />
              )
            }
          />
        ))}
      </Routes>
    </>
  );
}
